/* עיצוב כללי */
body {
  font-family: 'Rubik', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa; /* רקע האתר */
  color: #333; /* צבע טקסט כללי */
  overflow-x: hidden;
}

/* עיצוב בסיסי לאפליקציה */
.App {
  text-align: center;
}

/* עיצוב כותרת ראשית */
.App-header {
  background-color: #fff; /* צבע רקע לבן */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* עיצוב לוגו */
.App-logo {
  height: 150px;
  width: 150px;
  border-radius: 50%; /* עיגול הלוגו */
  margin-bottom: 20px;
  border: 3px solid #ccc; /* מסגרת מסביב ללוגו */
}

/* קישורים */
.App-link {
  color: #007bff; /* כחול לקישורים */
  text-decoration: none;
  font-weight: 500; /* משקל בינוני */
  transition: color 0.3s ease;
}

.App-link:hover {
  color: #0056b3; /* צבע כהה יותר בעת ריחוף */
}

/* אנימציה ללוגו (אופציונלי) */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}
