.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: right; /* ישור הטקסט לימין */
    padding: 40px;
    background-color: #f8f9fa; /* צבע רקע בהיר */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* צל */
    border-radius: 10px;
    margin: 20px auto;
    max-width: 900px; /* הגבלת רוחב הקונטיינר */
    direction: rtl; /* כיוון הטקסט מימין לשמאל */
  }
  
  .about-image {
    width: 100%;
    max-width: 400px; /* הגבלת רוחב התמונה */
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px; /* רווח בין התמונה לטקסט */
  }
  
  .about-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .about-text {
    margin: 20px 0;
    font-size: 1.2rem;
    color: #333;
    line-height: 1.6;
    font-family: 'Arial', sans-serif;
    padding: 0 20px; /* רווח פנימי */
  }
  
  .about-button .btn {
    padding: 12px 30px;
    background-color: #5e340d;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 20px; /* רווח בין הטקסט לכפתור */
  }
  
  .about-button .btn:hover {
    background-color: #41300b; /* צבע כהה יותר בעת ריחוף */
  }
  
  @media (max-width: 768px) {
    .about-container {
      padding: 20px;
    }
  
    .about-text {
      font-size: 1rem;
    }
  
    .about-image {
      max-width: 100%; /* התמונה תתפוס את כל רוחב המסך במובייל */
    }
  }
  