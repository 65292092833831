/* Header Container */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

/* Logo */
.logo {
    display: flex;
    align-items: center;
}

.logo-image {
    height: 60px;
    width: 60px;
    margin-right: 15px;
    border-radius: 50%; /* Makes the image circular */
}

@import url('https://fonts.googleapis.com/css2?family=Rubik+Dirt&display=swap');

/* כותרת הלוגו */
.logo-title {
    font-size: 30px; /* גודל פונט ענק */
    font-weight: 200; /* מודגש */
    color: #333;
    font-family: 'Rubik Dirt', sans-serif; /* פונט מעניין ומשופשף */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* אפקט של עומק */
    margin: 0;
    padding: 0;
}

/* Navigation Menu */
.nav-menu ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-menu ul li {
    margin-left: 150px;
}

.nav-menu ul li a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 600; /* Medium font weight */
    color: #555;
    transition: color 0.3s;
}

.nav-menu ul li a:hover {
    color: #007bff;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .header-container {
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
    }

    .logo {
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }

    .logo-image {
        height: 50px;
        width: 50px;
    }

    @import url('https://fonts.googleapis.com/css2?family=Rubik+Dirt&display=swap');

    /* כותרת הלוגו */
    .logo-title {
        font-size: 30px; /* גודל פונט ענק */
        font-weight: 200; /* מודגש */
        color: #333;
        font-family: 'Rubik Dirt', sans-serif; /* פונט מעניין ומשופשף */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* אפקט של עומק */
        margin: 0;
        padding: 0;
    }
    

    .nav-menu ul {
        display: flex; /* Displays the menu in a single row */
        justify-content: center; /* Centers the links */
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .nav-menu ul li {
        margin: 0 10px; /* Horizontal spacing between links */
        text-align: center;
    }

    .nav-menu ul li a {
        font-size: 16px;
        font-weight: 600; /* Medium font weight */
        color: #555;
        text-decoration: none;
        transition: color 0.3s;
    }

    .nav-menu ul li a:hover {
        color: #007bff;
    }
}
